import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import { useAppContext } from '../../contexts/auth';
import ActivateModal from './components/ActivateModal';
import AddModal from './components/AddModal';
import DeleteModal from './components/DeleteModal';
import DownloadModal from './components/DownloadModal';
import ListHeader from './components/header';
import ImportCsvModal from './components/ImportCsvModal';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import SuspendModal from './components/SuspendModal';
import useUsers from './useUsers';

export default function UsersList() {
  const {
    isLoading,
    fullUsers,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredUsers,
    setUserBeingSuspended,
    setModalSuspendShow,
    setUserBeingActivate,
    setModalActivateShow,
    setUserBeingDeleted,
    setModalDeleteShow,
    statusByCode,
    selectedCodStatus,
    handleTryAgain,
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    userBeingActivate,
    activateCollaborator,
    userBeingSuspended,
    suspendCollaborator,
    modalDeleteShow,
    userBeingDeleted,
    deleteCollaborator,
    modalCreateShow,
    collaboratorId,
    handleChangeStatusFilter,
    addCollaborator,
    modalActivateShow,
    modalSuspendShow,
    handleImportShow,
    handleCreateModalShow,
    handleImportModalClose,
    handleCreateModalClose,
    setCsvAction,
    csvAction,
    pagesQuantity,
    downloadUsers,
    downloadModalShow,
    setDownloadModalShow,
    setSelectedFilterDownload,
    selectedFilterDownload,
    currentPage,
    handlePageChange,
    handleCollaboratorIdChange,
    selectedCompany,
    handleCompanyChange,
    selectedCompanyForm,
    handleCompanyFormChange,
  } = useUsers();

  const { user } = useAppContext();
  const { viewByAssociatedCompanies } = user;

  const filteredListLength = filteredUsers?.length;
  const hasUsers = fullUsers?.length !== 0 && !!fullUsers;
  const searchNotFound = !filteredUsers && hasUsers;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullUsers?.length === 0);

  return (
    <>
      <Sidebar active="Users" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasUsers && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            selectedFilter={selectedCodStatus}
            onChangeSelectedStatus={handleChangeStatusFilter}
            selectedCompany={selectedCompany}
            handleCompanyChange={handleCompanyChange}
          />
        )}

        <ListHeader
          doesListExists={hasUsers}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="beneficiário"
          pluralLabel="beneficiários"
          filePathDownload={viewByAssociatedCompanies ? '/files/Modelo importar usuários com empresa associada Kiddlepass.csv' : '/files/Modelo importar usuários Kiddlepass.csv'}
          showDownloadModal={() => setDownloadModalShow(true)}
          isManualCreateAtModal
          handleImportShow={handleImportShow}
          handleCreateModalShow={handleCreateModalShow}
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
        />

        {hasUsers && (
          <List
            filteredList={filteredUsers}
            setUserBeingSuspended={setUserBeingSuspended}
            setModalSuspendShow={setModalSuspendShow}
            setUserBeingActivate={setUserBeingActivate}
            setModalActivateShow={setModalActivateShow}
            setUserBeingDeleted={setUserBeingDeleted}
            setModalDeleteShow={setModalDeleteShow}
          />
        )}

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado
                {' '}
                {searchTerm && 'para'}
                {' '}
                <strong>{searchTerm || ''}</strong>
                {' '}
                com status
                {' '}
                <strong>{statusByCode[selectedCodStatus]}</strong>
                {' '}
                na página atual
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter os colaboradores.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum colaborador cadastrado, clique no botão
                {' '}
                <strong>Novo colaborador</strong>
                {' '}
                ou
                {' '}
                <strong>Importar CSV</strong>
                {' '}
                acima para cadastrar seu(s) primeiro(s)!
              </>
            )}
          />
        )}

        <ImportCsvModal
          modalImportCsvShow={modalImportCsvShow}
          setCsvAction={setCsvAction}
          csvAction={csvAction}
          csvFileName={csvFileName}
          handleFileUpload={handleFileUpload}
          handleSubmitFile={handleSubmitFile}
          handleImportModalClose={handleImportModalClose}
        />

        <DownloadModal
          downloadModalShow={downloadModalShow}
          setSelectedFilterDownload={setSelectedFilterDownload}
          selectedFilterDownload={selectedFilterDownload}
          setDownloadModalShow={setDownloadModalShow}
          downloadUsers={downloadUsers}
        />

        <ActivateModal
          modalActivateShow={modalActivateShow}
          userBeingActivate={userBeingActivate}
          setModalActivateShow={setModalActivateShow}
          activateCollaborator={activateCollaborator}
        />

        <SuspendModal
          modalSuspendShow={modalSuspendShow}
          userBeingSuspended={userBeingSuspended}
          setModalSuspendShow={setModalSuspendShow}
          suspendCollaborator={suspendCollaborator}
        />

        <DeleteModal
          modalDeleteShow={modalDeleteShow}
          userBeingDeleted={userBeingDeleted}
          setModalDeleteShow={setModalDeleteShow}
          deleteCollaborator={deleteCollaborator}
        />

        <AddModal
          modalCreateShow={modalCreateShow}
          collaboratorId={collaboratorId}
          handleCollaboratorIdChange={handleCollaboratorIdChange}
          handleCreateModalClose={handleCreateModalClose}
          selectedCompanyForm={selectedCompanyForm}
          handleCompanyFormChange={handleCompanyFormChange}
          addCollaborator={addCollaborator}
        />

      </Transitions>
    </>
  );
}
